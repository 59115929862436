import React from 'react';
import {useState} from "react";
import SponsorshipContent from "../../components/sponsorship/desktop/SponsorshipContent";
import {Link} from "react-router-dom";
import sponsor_buy_pdf from "../../assets/pdf/Sponsorship&Buy_20_08_2024.pdf";

const SponsorshipPage = () => {
    const [tab, setTab] = useState(1)

    return (
        <section className="desktop_full_section desktop_download_section mb_50">
            <div className="column_content">
                <span className="h1 mt_10 semi_bold">Sponsorship & Buy</span>
                <span className="h3 light mt_10">Become a part of rebots revolt. Be a sponsor or buyer of our project. Before payment you need to read and agree <Link
                    to={sponsor_buy_pdf} target="_blank" className="semi_bold decoration">Sponsorship / Buy information document</Link> for deep understanding of this topic.</span>
            </div>
            <div className="desktop_api_block">
                <div className="tabs">
                    <div className={`tab ${tab === 1 ? 'active' : ''}`}
                         onClick={e => setTab(1)}>
                        <span>Sponsorship</span>
                    </div>
                    <div className={`tab disabled ${tab === 2 ? 'active' : ''}`}>
                        <span>Buy STX</span>
                    </div>
                </div>
                {tab === 1 && <SponsorshipContent/>}
            </div>
        </section>
    );
};

export default SponsorshipPage;