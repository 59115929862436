import React from 'react';
import {useState} from "react";
import {Link} from "react-router-dom";
import sponsor_buy_pdf from "../../assets/pdf/Sponsorship&Buy_20_08_2024.pdf";
import MobileSponsorshipContent from "../../components/sponsorship/mobile/MobileSponsorshipContent";

const MobileSponsorshipPage = () => {
    const [tabs, setTabs] = useState({
        t_1: false,
        t_2: false
    })

    return (
        <section className="mobile_full_section mobile_first_section mb_120">
            <div className="full_section_title">
                <span className="big semi_bold">Sponsorship & Buy</span>
                <span
                    className="small light">Become a part of rebots revolt. Be a sponsor or buyer of our project. Before payment you need to read and agree <Link
                    to={sponsor_buy_pdf} target="_blank" className="semi_bold decoration">Sponsorship / Buy information document</Link> for deep understanding of this topic.</span>
            </div>
            <div className="mobile_api_container">
                <div className="request">
                    <div className="title" onClick={e => setTabs({...tabs, t_1: !tabs.t_1})}>
                        <span>Become a sponsor</span>
                    </div>
                    {tabs.t_1 && <MobileSponsorshipContent/>}
                </div>
            </div>
            <div className="mobile_api_container">
                <div className="request disabled">
                    <div className="title">
                        <span>Buy STX</span>
                    </div>
                    {tabs.t_2 && <MobileSponsorshipContent/>}
                </div>
            </div>
        </section>
    );
};

export default MobileSponsorshipPage;