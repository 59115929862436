import React from 'react';
import logo_img from "../../assets/logo/logo.svg";
import {Link, useNavigate} from "react-router-dom";
import heart_icon from "../../assets/icon/heart_icon.svg";
import loop_icon from '../../assets/icon/loop_icon.svg'
import logical_icon from '../../assets/icon/logical_icon.svg'
import close_burger_icon from "../../assets/icon/close_burger_icon.svg";
import burger_icon from "../../assets/icon/burgen_icon.svg";
import {useEffect, useState} from "react";

const MobileHeader = () => {

    const [open, setOpen] = useState(false)

    const navigate = useNavigate()

    const customNav = (url) => {
        setOpen(false)
        navigate(url)
    }

    useEffect(() => {
        const header = document.querySelector("header");
        const intercept = document.createElement("div");

        intercept.setAttribute("data-observer-intercept", "");
        header.before(intercept);

        const observer = new IntersectionObserver(([entry]) => {
            header.classList.toggle("active", !entry.isIntersecting);
        });

        observer.observe(intercept);
    }, []);

    return (
        <header className={`mobile_header ${open ? 'shadow' : ''}`} style={{zIndex: '100 !important'}}>
            <div className="row">
                <div className="logo" style={{maxWidth: 'max-content', cursor: 'pointer'}}
                     onClick={e => navigate("/")}>
                    <img src={logo_img} alt="settix logo"/>
                </div>
                {open ? (
                    <div className="burger_icon" onClick={e => setOpen(false)}>
                        <img src={close_burger_icon} alt="burger icon"/>
                    </div>
                ) : (
                    <div className="burger_icon" onClick={e => setOpen(true)}>
                        <img src={burger_icon} alt="close burger icon"/>
                    </div>
                )}
            </div>
            {open &&
                <>
                    <div className="row cut_row">
                        <div className="nav_links">
                            <div className="nav_link" onClick={() => window.open('https://t.me/SearchSTXThroughBot', '_blank')}>
                                <img src={loop_icon} alt="stx miner icon"/>
                                <span>Open miner</span>
                            </div>
                            <div className="nav_link" onClick={e => customNav("/stxlogical")}>
                                <img src={logical_icon} alt="stxlogical icon"/>
                                <span>STXLogical</span>
                            </div>
                        </div>
                    </div>
                    <div className="row cut_row">
                        <Link to="/sponsorship" className="sponsor_nav_link" onClick={e => setOpen(false)}>
                            <img src={heart_icon} alt="settix sponsorhip icon"/>
                            SPONSORSHIP / BUY
                        </Link>
                    </div>
                </>
            }
        </header>
    );
};

export default MobileHeader;