import React from 'react';
import stxlogical_logo from "../../assets/logo/stxlogical_svg.svg";
import {Link} from "react-router-dom";

const StxLogicalPage = () => {
    return (
        <section className="desktop_full_section desktop_download_section mb_50">
            <div className="column_content">
                <div>
                    <img src={stxlogical_logo} alt="stxlogical logo" className="section_desktop_img"/>
                </div>
                <span className="h1 mt_10 semi_bold">STXLogical</span>
                <span className="h3 light mt_10">STXLogical is a chain of project which used STX blockchain and consists all applications related to STX coin. STXLogical is not a stage, this chain will be in all stages and will be updated sometimes. Main goals of STXLogical are applications such as miners and developer instruments for Settix users. Currently STXLogical consists only 1 application -&nbsp;
                    <Link to="/stxlogical/search-the-stx" className="semi_bold decoration">Search the STX</Link>.</span>
                <span className="desktop_article_date">Updated at <span className="date">24.10.2024</span></span>
            </div>
        </section>
    );
};

export default StxLogicalPage;