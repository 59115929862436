import React, {useState} from 'react';
import usdttrc20_icon from "../../../assets/coin/usdttrc20_icon.svg";

const MobileSponsorshipContent = () => {

    const [wallet, setWallet] = useState('TMUX7hTwKhgfbRRjtzaMAinmm8GFD3QE4J')

    const copyWallet = () => {
        navigator.clipboard.writeText(wallet).then(() => {
            alert('Wallet copied to your clipboard. Be careful when you send money and check wallet match.')
        });
    }

    return (
        <div className="content">
            <div className="row">
                <span className="big uppercase">Be part of the robots revolt, become a sponsor!</span>
            </div>
            <div className="row">
                <div className="currency_wallet_input">
                    <div className="currency_icon">
                        <img src={usdttrc20_icon} alt="usdt trc-20 icon"/>
                    </div>
                    <div className="currency_wallet">
                        <span className="mobile">{wallet}</span>
                    </div>
                </div>
            </div>
            <div className="row">
                <button className="desktop_btn_default desktop_section_btn small_sponsor_btn"
                        onClick={() => copyWallet()}>Copy address</button>
            </div>
            <div className="row">
                <span className="light mobile_small_text">Your money will be spent on server and quality development of new features.<br/><br/>Settix is transaparent blockchain, thus you can write any proposal in transaction note and we will consider your message.</span>
            </div>
        </div>
    );
};

export default MobileSponsorshipContent;