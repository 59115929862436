import React from 'react';
import robot_img from '../../assets/robot_img.svg'
import btn_element_img from '../../assets/decoration/btn_element_img.svg'
import screens_img from "../../assets/screens.png";
import search_logo from "../../assets/logo/search_logo.png"
import search_loop_logo from "../../assets/logo/search_loop_logo.png"
import search_img1 from "../../assets/search_img1.png"
import search_img2 from "../../assets/search_img2.png"
import search_img3 from "../../assets/search_img3.png"
import search_img4 from "../../assets/search_img4.png"
import {Link} from "react-router-dom";

const MainPage = () => {

    return (
        <>
            <section className="desktop_section desktop_main_section desktop_section_with_arrow">
                <div className="divided_section">
                    <div className="left">
                        <img src={robot_img} alt="settix robot image" className="robot_img img_opacity"/>
                        <span className="h1 light mt_10">Welcome to <span
                            className="semi_bold">Robots revolt</span></span>
                        <span className="h3 light mt_10">Every day we working without coffee breaks for deploy our blockchain as quickly as possible. Connect to the Robots revolt community on Telegram and be first when robots revolt will start.</span>
                        <div className="section_decorated_btn">
                            <img src={btn_element_img} alt="button element decoration"/>
                            <button className="desktop_btn_default desktop_section_btn"
                                    onClick={() => window.open('https://t.me/robotsrevolt', '_blank')}>Join us on
                                Telegram
                            </button>
                        </div>
                    </div>
                    <div className="right screens_block">
                        <img src={screens_img} alt="settix landing screens"/>
                    </div>
                </div>
            </section>
            <section className="desktop_padding_section desktop_search_section">
                <div className="search_title">
                    <img src={search_logo} alt="search the stx logo 1"/>
                    <span>Mining in active phase</span>
                    <img src={search_loop_logo} alt="search the stx logo 2" style={{position: 'relative', top: '7px'}}/>
                </div>
                <div className="search_text_content">
                    <span>Through Search the STX you can try your luck and receive STX as reward. Search the STX is a game in Telegram which using cryptography and blockchain approaches for mining. We will publish a video about realization to trust the mining process and will notify all miners and update this article. More information you can find at <Link
                        to="/stxlogical">STXLogical page</Link> and in <Link to="https://t.me/robotsrevolt"
                                                                             target="_blank">Telegram channel</Link>.</span>
                </div>
                <div className="search_images_content">
                    <img src={search_img1} alt="search the stx image preview 1"/>
                    <img src={search_img2} alt="search the stx image preview 2"/>
                    <img src={search_img3} alt="search the stx image preview 3"/>
                    <img src={search_img4} alt="search the stx image code"/>
                </div>
                <div className="search_navigation_block">
                    <span>🤖 Before using read <Link
                        to="/stxlogical/search-the-stx/how-to-use">How to use Search the STX?</Link></span>
                    <button className="desktop_btn_default desktop_section_btn button_big_paddings"
                            onClick={() => window.open('https://t.me/robotsrevolt', '_blank')}>Go to Search the STX
                    </button>
                </div>
            </section>
        </>
    );
};

export default MainPage;