import React from 'react';
import robot_img from "../../assets/robot_img.svg";

const MobileNotFoundPage = () => {
    return (
        <section className="mobile_section mobile_main_section">
            <div className="column_block" style={{margin: '0 auto'}}>
                <img src={robot_img} alt="settix robot image" className="robot_img img_opacity"
                     style={{margin: '0 auto'}}/>
                <span className="big semi_bold">404: <span className="light">Page not found</span></span>
            </div>
        </section>
    );
};

export default MobileNotFoundPage;