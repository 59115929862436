import React from 'react';
import search_logo from "../../assets/logo/search_logo.png";
import {Link} from "react-router-dom";

const MobileStxLogicalStstxHowToUsePage = () => {
    return (
        <>
            <section className="mobile_full_section mobile_first_section mb_120">
                <div className="full_section_title">
                    <div className="logo_block">
                        <img src={search_logo} alt="search the stx logo 1" className="section_desktop_img"/>
                    </div>
                    <span className="big semi_bold">STXLogical: <span className="light">STSTX - How to use?</span></span>
                    <span
                        className="small light">STXLogical: Search the STX using for pre-release Settix blockchain mining to receive STX by playing the game in Telegram messenger. When user start bot first time will receive 20 attempts that equals to 20 games. 1 attempt = 1 game. Game created by Guess the Card principle. Game consists 9 cards, where only 1 card is right. If miner guess the win card will receive STX. Every 30 minutes each user receive 2 attempts. Thus, every day miner will receive 48 attempts that equals 48 games.
                        <br/><br/>
                        <span className="mobile_important_span">IMPORTANT:&nbsp;</span>When user start bot first time will generate identify code which assigned to miner and be unique. So, each miner has own unique code. Miner should use this code for identify in Search the STX. When Settix blockchain will deploy each user can receive own balance on Settix wallet. Received STX attached to Telegram account. So if you delete account, lose access to account or share your identify code you will lose all of your STX. After deploy you need to done identify with your Telegram account to receive balance.
                        <br/><br/>
                        Good luck and happy mining!
                        <br/><br/>
                        Learn more about <Link
                            to="/stxlogical"
                            className="semi_bold decoration">STXLogical</Link></span>
                    <span className="mobile_article_date">Updated at <span
                        className="date">24.10.2024</span></span>
                </div>
            </section>
        </>
    );
};

export default MobileStxLogicalStstxHowToUsePage;