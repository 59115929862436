import React from 'react';
import robot_img from "../../assets/robot_img.svg";

const NotFoundPage = () => {
    return (
        <section className="desktop_section">
            <div className="column_block">
                <img src={robot_img} alt="settix robot image" className="robot_img img_opacity" style={{margin: '0 auto'}}/>
                <span className="h1 semi_bold">404: <span className="light">Page not found</span></span>
            </div>
        </section>
    );
};

export default NotFoundPage;