import React from 'react';
import stxlogical_logo from "../../assets/logo/stxlogical_svg.svg";
import {Link} from "react-router-dom";
import search_logo from "../../assets/logo/search_logo.png";

const MobileStxLogicalStstxPage = () => {
    return (
        <>
            <section className="mobile_full_section mobile_first_section mb_120">
                <div className="full_section_title">
                    <div className="logo_block">
                        <img src={search_logo} alt="search the stx logo 1" className="section_desktop_img"/>
                    </div>
                    <span className="big semi_bold">STXLogical: <span className="light">Search the STX</span></span>
                    <span
                        className="small light">Search the STX is a game which based on Telegram and use to mining STX coin through guess-game. Game supports crypto algorithms and chain-realization to trust mining process and lock unauthorized changes in chain such as game duplication, user balance abusing etc. We will publish a video about realization to trust the mining process and will notify all miners and update this article.
                        <br/><br/>
                        <span className="mobile_important_span">IMPORTANT:&nbsp;</span>Read <Link
                            to="/stxlogical/search-the-stx/how-to-use"
                            className="semi_bold decoration">How to use Search the STX?</Link> before using! You can lose all of your received money if you neglect to read.
                        <br/><br/>
                        Learn more about <Link
                            to="/stxlogical"
                            className="semi_bold decoration">STXLogical</Link></span>
                    <span className="mobile_article_date">Updated at <span
                        className="date">24.10.2024</span></span>
                </div>
            </section>
        </>
    );
};

export default MobileStxLogicalStstxPage;