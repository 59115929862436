import React from 'react';
import telegram_logo from '../../assets/logo/telegram_logo.svg'
import instagram_logo from '../../assets/logo/instagram_logo.svg'
import youtube_logo from '../../assets/logo/youtube_logo.svg'
import {Link} from "react-router-dom";


const Footer = () => {
    return (
        <footer className="desktop_footer">
            <span className="light">made with  ❤️  by Robots revolt community</span>
            <Link to="mailto:support@settix.xyz" className="footer_email">support@settix.xyz</Link>
            <Link to="mailto:marketing@settix.xyz" className="footer_email">marketing@settix.xyz</Link>
            <div className="social">
                <Link to="https://t.me/robotsrevolt" target="_blank"><img src={telegram_logo} alt=""/></Link>
                <Link to="http://instagram.com/robotsrevoltcommunity" target="_blank"><img src={instagram_logo} alt=""/></Link>
                <Link to="https://youtube.com/@RobotsRevolt" target="_blank"><img src={youtube_logo} alt=""/></Link>
            </div>

        </footer>
    );
};

export default Footer;