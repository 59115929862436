import React, {useState} from 'react';
import usdttrc20_icon from "../../../assets/coin/usdttrc20_icon.svg";
import copy_icon from "../../../assets/icon/copy_icon.svg";

const SponsorshipContent = () => {

    const [wallet, setWallet] = useState('TMUX7hTwKhgfbRRjtzaMAinmm8GFD3QE4J')

    const copyWallet = () => {
        navigator.clipboard.writeText(wallet).then(() => {
            alert('Wallet copied to your clipboard. Be careful when you send money and check wallet match.')
        });
    }

    return (
        <div className="api_content">
            <div className="row">
                <span></span>
                <span className="basic_text">Be part of the robots revolt, become a sponsor!</span>
            </div>
            <div className="row">
                <div className="currency_wallet_input">
                    <div className="currency_icon">
                        <img src={usdttrc20_icon} alt="usdt trc-20 icon"/>
                    </div>
                    <div className="currency_wallet">
                        <span>{wallet}</span>
                    </div>
                    <div className="currency_copy" onClick={() => copyWallet()}>
                        <img src={copy_icon} alt="copy wallet address icon"/>
                    </div>
                </div>
            </div>
            <div className="row">
                <span></span>
                <span>Your money will be spent on server and quality development of new features.<br/>Settix is transaparent blockchain, thus you can write any proposal in transaction note and we will consider your message.</span>
            </div>
        </div>
    );
};

export default SponsorshipContent;