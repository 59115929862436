import './App.css';
import './Mobile.css';
import Header from "./components/header/Header";
import {Route, Routes, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import MobileHeader from "./components/header/MobileHeader";
import MainPage from "./pages/desktop/MainPage";
import MobileMainPage from "./pages/mobile/MobileMainPage";
import Footer from "./components/footer/Footer";
import MobileFooter from "./components/footer/MobileFooter";
import SponsorshipPage from "./pages/desktop/SponsorshipPage";
import MobileSponsorshipPage from "./pages/mobile/MobileSponsorshipPage";
import NotFoundPage from "./pages/desktop/NotFoundPage";
import MobileNotFoundPage from "./pages/mobile/MobileNotFoundPage";
import StxLogicalPage from "./pages/desktop/StxLogicalPage";
import StxLogicalStstxPage from "./pages/desktop/StxLogicalSTSTXPage";
import StxLogicalStstxHowToUsePage from "./pages/desktop/StxLogicalSTSTXHowToUsePage";
import MobileStxLogicalPage from "./pages/mobile/MobileStxLogicalPage";
import MobileStxLogicalStstxPage from "./pages/mobile/MobileStxLogicalSTSTXPage";
import MobileStxLogicalStstxHowToUsePage from "./pages/mobile/MobileStxLogicalSTSTXHowToUsePage";

function App() {

    const { pathname } = useLocation();

    const [isMobileScreen, setIsMobileScreen] = useState(isMobile);

    useEffect(() => {
        window.scrollTo(0, 0);
        const handleResize = () => {
            const mobileBreakpoint = 768;
            setIsMobileScreen(window.innerWidth <= mobileBreakpoint);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [pathname]);

    return (
        <>
            {!isMobileScreen && <Header />}
            {isMobileScreen && <MobileHeader />}
            <Routes>
                {!isMobileScreen &&
                    <>
                        <Route exact path="/stxlogical/search-the-stx/how-to-use" element={<StxLogicalStstxHowToUsePage />} />
                        <Route exact path="/stxlogical/search-the-stx" element={<StxLogicalStstxPage />} />
                        <Route exact path="/stxlogical" element={<StxLogicalPage />} />
                        <Route exact path="/sponsorship" element={<SponsorshipPage />} />
                        <Route exact path="/" element={<MainPage />} />
                        <Route exact path="*" element={<NotFoundPage />} />
                    </>
                }
                {isMobileScreen &&
                    <>
                        <Route exact path="/stxlogical/search-the-stx/how-to-use" element={<MobileStxLogicalStstxHowToUsePage />} />
                        <Route exact path="/stxlogical/search-the-stx" element={<MobileStxLogicalStstxPage />} />
                        <Route exact path="/stxlogical" element={<MobileStxLogicalPage />} />
                        <Route exact path="/sponsorship" element={<MobileSponsorshipPage />} />
                        <Route exact path="/" element={<MobileMainPage />} />
                        <Route exact path="*" element={<MobileNotFoundPage />} />
                    </>
                }
            </Routes>
            {!isMobileScreen && <Footer />}
            {isMobileScreen && <MobileFooter />}
        </>
    );
}

export default App;
