import React from 'react';
import logo_img from '../../assets/logo/logo.svg'
import heart_icon from '../../assets/icon/heart_icon.svg'
import loop_icon from '../../assets/icon/loop_icon.svg'
import logical_icon from '../../assets/icon/logical_icon.svg'
import {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";

const Header = () => {

    const navigate = useNavigate()

    useEffect(() => {
        const header = document.querySelector("header");
        const intercept = document.createElement("div");

        intercept.setAttribute("data-observer-intercept", "");
        header.before(intercept);

        const observer = new IntersectionObserver(([entry]) => {
            header.classList.toggle("active", !entry.isIntersecting);
        });

        observer.observe(intercept);
    }, []);

    return (
        <>
            <header className="desktop_header">
                <div className="nav_content">
                    <div className="desktop_nav_icon_link" onClick={() => window.open('https://t.me/SearchSTXThroughBot', '_blank')}>
                        <img src={loop_icon} alt="stx miner icon"/>
                        <span>Open miner</span>
                    </div>
                    <div className="desktop_nav_icon_link" onClick={e => navigate("/stxlogical")}>
                        <img src={logical_icon} alt="stxlogical icon"/>
                        <span>STXLogical</span>
                    </div>
                </div>
                <div style={{maxWidth: 'max-content', cursor: 'pointer'}}
                     onClick={e => navigate("/")}>
                    <img src={logo_img} alt="settix logo"/>
                </div>
                <div className="nav_right_content">
                    <Link to="/sponsorship" className="sponsor_nav_link">
                        <img src={heart_icon} alt="settix sponsorhip icon"/>
                        SPONSORSHIP / BUY
                    </Link>
                </div>
            </header>
        </>
    );
};

export default Header;